$(document).ready(function() {
    $('select').each(function() {
      
      var $this = $(this),numberOfOptions = $(this).children('option').length;     
            if (!$(this)[0].classList.contains('select-standart')) {
                $this.addClass('select-hidden');
                $this.wrap('<div class="select"></div>');
                $this.after('<div class="select-styled"></div>');

                var $styledSelect = $this.next('div.select-styled');
                $styledSelect.text(
                    $this
                    .children('option')
                    .eq(0)
                    .text()
                );

                var $list = $('<ul />', {
                    class: 'select-options',
                }).insertAfter($styledSelect);

                for (var i = 0; i < numberOfOptions; i++) {
                    $('<li />', {
                        text: $this
                            .children('option')
                            .eq(i)
                            .text(),
                        rel: $this
                            .children('option')
                            .eq(i)
                            .val(),
                    }).appendTo($list);
                }

                var $listItems = $list.children('li');

                $styledSelect.click(function(e) {
                    e.stopPropagation();
                    $('div.select-styled.active')
                        .not(this)
                        .each(function() {
                            $(this)
                                .removeClass('active')
                                .next('ul.select-options')
                                .hide();
                        });
                    $(this)
                        .toggleClass('active')
                        .next('ul.select-options')
                        .toggle();
                });

                $listItems.click(function(e) {
                    e.stopPropagation();
                    $styledSelect.text($(this).text()).removeClass('active');
                    $this.val($(this).attr('rel'));
                    $list.hide();
                    if (!!window.selectOnChangeHandlers) {
                        window.selectOnChangeHandlers.forEach(onChangeHandler => {
                            onChangeHandler($this.attr('id'));
                        });
                    }
                    //console.log($this.val());
                });

                $(document).click(function() {
                    $styledSelect.removeClass('active');
                    $list.hide();
                });
            }
            }
          );
    
});

$(document).ready(function() {
    $('.upload').wrap("<div class='custom-upload-wrap'></div>");
    var $customButton =
        "<span class='file-name'>Select a File</span><button type='button' class='file-upload-button'>Upload a File</button>";
    $('.upload').before($customButton);
    $('.upload').change(function() {
        var fileName = $(this)
            .val()
            .split('\\')
            .pop();
        $(this)
            .parent()
            .find('.file-name')
            .text(fileName);
    });
    //alert(fileName)
});